import { cacheCatalogueFilterParams } from '@cache'
import { Container, MobileGolfClubModelSearch } from '@components'
import { Loader } from '@elements'
import { useBrowsePage, useDrawer, useWindowSize } from '@hooks'
import MobileFiltersArrow from '@images/link-arrow.svg'
import Options from '@images/option.svg'
import { BrowseViewComponents } from '@pages/components'
import { useLocation } from '@reach/router'
import React, { useEffect } from 'react'
import '../browse.scss'

interface BrowseViewProps {
  slug: string
}

const BrowseView: React.FC<BrowseViewProps> = props => {
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { pathname, search } = useLocation()
  const { openDrawer } = useDrawer('MobileBrowseFiltersDrawer')
  const { loading, browsePageData } = useBrowsePage(props.slug)
  const pageSlug = pathname.split('/')[2]

  useEffect(() => {
    cacheCatalogueFilterParams(search)
  }, [search])

  const renderMobileModelSearch = () => {
    if (isMobileScreen)
      return (
        <div className="browse-mobile-search">
          <MobileGolfClubModelSearch />
        </div>
      )
  }
  const renderMobileFilters = () => {
    if (isMobileScreen)
      return (
        <div className={'browse-mobile-heading-filters-button'} onClick={openDrawer}>
          <div>
            <Options />
            FILTER THE CATALOGUE
          </div>
          <MobileFiltersArrow />
        </div>
      )
  }
  const renderDesktopFilters = () => {
    if (!isMobileScreen && browsePage) return <BrowseViewComponents.BrowsePageFilters {...browsePage} />
  }

  const browsePage = browsePageData && browsePageData.res

  if (loading) return <Loader />
  if (!browsePage) return null

  return (
    <div className={`browse browse-${pageSlug}`}>
      <Container>
        <BrowseViewComponents.BrowsePageHeader {...browsePage} />
        {renderMobileModelSearch()}
        <div>
          {renderMobileFilters()}
          <div className={'browse-grid'}>
            {renderDesktopFilters()}
            <BrowseViewComponents.BrowsePageProducts {...browsePage} />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default BrowseView
